import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../../../components/Layout"
import styled from "styled-components"

import EvmEventsSegment from "./EvmEventsSegment"

const Title = styled.h3`
    display: block;
    margin: 0 0 1rem 0;
`
const SubTitle = styled.h4`
    display: block;
    margin: 0 0 1.5rem 0;
    color: #999;
`
const EventContainer = styled.div`
    display: block;
`
const EventSelector = styled.div<{isSelected: boolean}>`
    display: inline-block;
    padding: 0 1em;
    border: 1px solid #999;
    background: ${props => props.isSelected ? '#999' : 'transparent'};

    &:hover {
        cursor: pointer;
    }
`

const EvmSanctions = () => {
    const [selectedContract, setSelectedContract] = useState<number>(0);

    const EVENTS: {address: string, title: string}[] = [{
        "address": "0x40c57923924b5c5c5455c48d93317139addac8fb",
        "title": "Chain Analysis Sanctions"
    }, {
        "address": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        "title": "USDT Tether"
    }]

    const EVENT_MAPPING: {actionName: string, description: string; background: string, renderName: string }[] = [{
        actionName: "addToSanctionsList",
        description: "Address is on an economic/trade embargo lists from organisations including US, EU, or UN. The list is includes SDN and OFAC.",
        background: "#db4b4b",
        renderName: "🔒 Sanctioned"
    }, {
        actionName: "addBlackList",
        description: "The address is unable to tranfer USDT or receive USDT on this chain.",
        background: "#db4b4b",
        renderName: "🔒 Blacklisted"
    }, {
        actionName: "removeFromSanctionsList",
        description: "The address has been removed from an economic/trade embargo list. The list is includes SDN and OFAC.",
        background: "#abef2a",
        renderName: "🔓 Unsanctioned"
    }, {
        actionName: "removeBlackList",
        description: "The address is now able to transfer USDT and receive USDT on this chain.",
        background: "#abef2a",
        renderName: "🔓 Unblacklisted"
    }, {
        actionName: "transferOwnership",
        description: "The smart contract now has a new owner that has privileges within the contract.",
        background: "#efe42a",
        renderName: "👤 New Contract Owner"
    }, {
        actionName: "pause",
        description: "The contract has been paused. State changes are reverted.",
        background: "#2aefed",
        renderName: "⏸ Paused"
    }, {
        actionName: "unpause",
        description: "The contract has been unpaused. State changes are now allowed.",
        background: "#2ac5ef",
        renderName: "▶️ Paused"
    }, {
        actionName: "destroyBlackFunds",
        description: "These funds were owned by a blacklisted address and are now destroyed and removed from circulation.",
        background: "#ef2a2a",
        renderName: "🔥 Funds Destroyed"
    }, {
        actionName: "issue",
        description: "New tokens were minted to the smart contract owner's address",
        background: "#df2aef",
        renderName: "🖨 Issued"
    }, {
        actionName: "redeem",
        description: "",
        background: "#ef952a",
        renderName: "💰 Redeem"
    }, {
        actionName: "deprecate",
        description: "Smart contract has been upgraded, this one is now deprecated.",
        background: "#9a4e33",
        renderName: "☄️ Contract Deprecated"
    }]

    return (
        <Layout largeWidthView={true}>
            <Title>
                <Link to="/">~/</Link>&nbsp;Evm Sanctions
            </Title>
            <SubTitle>
                Some smart-contracts, especially Stablecoins, have the ability to block addresses from recieving and {' '}
                sending assets due to KYC/AML regulations. {' '}
                This tool has an index of notable interactions/function calls on specific smart contracts across{' '}
                specific EVM chains so that we can easily see which addresses are blocked from such events (read: *sanctioned*).
            </SubTitle>
            <SubTitle>
                Note: Not every event that is emitted is indexed (such as USDT transfers). This application only cares for blacklist-type events.
            </SubTitle>

            {
                EVENTS.map((event: {address: string, title: string}, idx: number) => {
                    return(
                        <EventSelector isSelected={idx === selectedContract} onClick={() => setSelectedContract(idx)}>
                            {event.title}
                        </EventSelector>
                    )
                })
            }

            <EventContainer>
                <EvmEventsSegment 
                    address={EVENTS[selectedContract].address}
                    title={EVENTS[selectedContract].title}
                    mapping={EVENT_MAPPING}
                />
            </EventContainer>

        </Layout>
    );
};

export default EvmSanctions;
